<template>
  <v-container>
    <!-- Start overview section-->
    <v-row>
      <v-col md="12" cols="12">
        <v-card>
          <v-img
            height="250"
            :src="require('@/assets/renoir/renoir.png')"
          ></v-img>

          <v-card-text>
            <v-container class="pt0" style="margin-top: -80px">
              <v-row>
                <v-col>
                  <v-card
                    class="info"
                    height="150px"
                    elevation="0"
                    @click="navigateToAbsent()"
                  >
                    <v-card-title class="h6 white--text">{{
                      $t("msh.dashboard.quickOverview.presenceTitle")
                    }}</v-card-title>
                    <v-card-text
                      class="white--text pt-0"
                      v-if="building && building.getStats()"
                    >
                      {{
                        $tc(
                          "msh.dashboard.quickOverview.presenceText",
                          totalAbsent
                        )
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="warning"
                    height="150px"
                    elevation="0"
                    @click="navigateToDeviceOffline()"
                  >
                    <v-card-title class="h6 white--text">{{
                      $t("msh.dashboard.quickOverview.statusTitle")
                    }}</v-card-title>
                    <v-card-text class="white--text pt-0" v-if="deviceCount">
                      {{
                        $tc(
                          "msh.dashboard.quickOverview.statusText",
                          offlineDevice
                        )
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card :class="alertClass" height="150px" elevation="0">
                    <v-card-title class="h6 white--text">{{
                      $t("msh.dashboard.incidents.title")
                    }}</v-card-title>
                    <v-card-text
                      class="white--text pt-0"
                      v-if="building && building.getStats()"
                    >
                      {{
                        $tc(
                          "msh.dashboard.quickOverview.alertText",
                          building.getStats().getTotalAlerts()
                        )
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End overview section-->
    </v-row>
    <!-- Start incident list section-->
    <v-row>
      <v-col>
        <v-dialog
          v-model="ackDialog"
          max-width="400px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5"> Confirm </span>
            </v-card-title>

            <v-card-text> Mark this alert as resolved ? </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="ackClose">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="acknowledge"
                :loading="ackLoading"
              >
                Acknowledge
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-card-title>Incidents</v-card-title>
          <v-data-table :headers="headers" :items="alerts">
            <template v-slot:header.creationTime="{}">
              <v-icon small>fa-calendar</v-icon>
            </template>

            <template v-slot:item.creationTime="{ item }">
              <!-- Extract date following defined i18n short option -->
              <span v-if="item && item.creationTime">
                {{ $d(new Date(item.creationTime.seconds) * 1000, "short") }}
              </span>
              <span v-else></span>
            </template>

            <template v-slot:item.event="{ item }">
              <!-- Extract date following defined i18n short option -->
              <span v-if="item && item.event.fall">
                {{ $t("msh.floor.event.fall") }}
              </span>
              <span v-else>{{ $t("msh.floor.event.generic") }}</span>
            </template>

            <template v-slot:item.acknowledge="{ item }">
              <v-btn text @click="confirmAcknowledge(item.alertId)">
                Mark as resolved
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- End incident list section-->
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import router from "@/router";
import {
  BUILDING_COUNT_DEVICE_REQUEST,
  BUILDING_FETCH_ALERTS_REQUEST,
  STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE,
} from "@/store/constants";

export default {
  data() {
    return {
      device: null,
      service: null,
      dialog: false,
      jsonDetail: "",
      search: "",
      headers: [
        {
          text: this.$t("msh.dashboard.incidents.floor"),
          align: "start",
          sortable: true,
          value: "floorLabel",
        },
        {
          text: this.$t("msh.dashboard.incidents.appartment"),
          value: "structureLabel",
          sortable: true,
        },
        { text: "", value: "creationTime" },
        { text: "", value: "event" },
        { text: "", value: "acknowledge" },
      ],
      ackDialog: false,
      ackLoading: false,
    };
  },
  methods: {
    //
    // Navigate
    navigateToAbsent() {
      router.push({
        name: "floors",
        params: {
          buildingId: router.currentRoute.params.buildingId,
        },
        query: {
          q: "presence:1,3",
        },
      });
    },
    navigateToDeviceOffline() {
      router.push({
        name: "listDevices",
        params: {
          buildingId: router.currentRoute.params.buildingId,
        },
        query: {
          q: "offline",
        },
      });
    },
    //
    // Render date from an adl event
    //
    renderDate(adl) {
      if (adl && adl.event) {
        if (adl.event.fall) {
          return this.$d(
            new Date(adl.event.fall.timestamp.seconds * 1000),
            "short"
          );
        } else if (adl.event.presence) {
          return this.$d(
            new Date(adl.event.presence.timestamp.seconds * 1000),
            "short"
          );
        } else if (adl.event.activityLevel) {
          return "";
        } else if (adl.event.audioClassification) {
          return "";
        } else if (adl.event.connectivity) {
          return "";
        } else if (adl.event.heartRate) {
          return "";
        } else if (adl.event.respirationRate) {
          return "";
        } else if (adl.event.temperature) {
          return "";
        } else if (adl.event.pressure) {
          return "";
        } else if (adl.event.humidity) {
          return "";
        } else if (adl.event.lightIntensity) {
          return "";
        }
      }
      return "";
    },
    //
    // Render title from an adl event
    //
    renderTitle(adl) {
      if (adl && adl.event) {
        if (adl.event.fall) {
          // return this.$tc("msh.floor.event.withAppt.fall", 'test');
          return this.$t("msh.floor.event.withAppt.fall", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.presence) {
          return this.$t("msh.floor.event.withAppt.presence", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.activityLevel) {
          return this.$t("msh.floor.event.withAppt.activity", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.audioClassification) {
          return this.$t("msh.floor.event.withAppt.audioClassification", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.connectivity) {
          return this.$t("msh.floor.event.withAppt.connectivity", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.heartRate) {
          return this.$t("msh.floor.event.withAppt.heartRate", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.respirationRate) {
          return this.$t("msh.floor.event.withAppt.respirationRate", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.temperature) {
          return this.$t("msh.floor.event.withAppt.temperature", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.pressure) {
          return this.$t("msh.floor.event.withAppt.pressure", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.humidity) {
          return this.$t("msh.floor.event.withAppt.humidity", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.lightIntensity) {
          return this.$t("msh.floor.event.withAppt.lightIntensity", {
            appt: this.renderLocation(adl.structure),
          });
        }
      }
      return this.$t("msh.floor.event.generic");
    },
    //
    // Return structure label
    //
    renderLocation(structure) {
      if (structure && structure.business) {
        return structure.business.label;
      }
      return "";
    },
    //
    // Render caption from an adl event
    // TODO Define actual caption info per adl event
    //
    renderCaption(adl) {
      if (adl && adl.event) {
        if (adl.event.fall) {
          let fallLevel = this.$t(
            "msh.floor.event.detail.fallLevel." + adl.event.fall.level
          );
          return this.$t("msh.floor.event.detail.fall", {
            type: fallLevel,
            source: [],
          });
        } else if (adl.event.presence) {
          return this.$t("msh.floor.event.withAppt.presence", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.activityLevel) {
          return this.$t("msh.floor.event.withAppt.activity", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.audioClassification) {
          return this.$t("msh.floor.event.withAppt.", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.connectivity) {
          return this.$t("msh.floor.event.withAppt.", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.heartRate) {
          return this.$t("msh.floor.event.withAppt.", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.respirationRate) {
          return this.$t("msh.floor.event.withAppt.", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.temperature) {
          return this.$t("msh.floor.event.withAppt.temperature", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.pressure) {
          return this.$t("msh.floor.event.withAppt.pressure", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.humidity) {
          return this.$t("msh.floor.event.withAppt.humidity", {
            appt: this.renderLocation(adl.structure),
          });
        } else if (adl.event.lightIntensity) {
          return this.$t("msh.floor.event.withAppt.lightIntensity", {
            appt: this.renderLocation(adl.structure),
          });
        }
      }
      return "";
    },
    //
    // Define the timeline color based on the adl event
    //
    renderTimelineColor(adl) {
      if (adl && adl.event && adl.event.fall) {
        return "warning";
      }
      return "info";
    },
    //
    // showJson
    //
    showJson(adl) {
      this.jsonDetail = JSON.stringify(adl, null, 2);
      this.dialog = true;
    },
    //
    // Close the dialog window and erase the data
    //
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.jsonDetail = "";
      });
    },

    /**
     * Popup alert confirm box
     */
    confirmAcknowledge(alertId) {
      this.confirmAlertId = alertId;
      this.ackDialog = true;
    },
    /**
     * Acknowledge as alert / mark as resolved
     */
    acknowledge() {
      this.ackLoading = true;
      store
        .dispatch(
          STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE,
          this.confirmAlertId
        )
        .then((response) => {
          store.dispatch(
            BUILDING_FETCH_ALERTS_REQUEST,
            router.currentRoute.params.buildingId
          );
          this.ackLoading = false;
          this.ackDialog = false;
        })
        .catch((err) => {
          this.ackLoading = false;
        });
    },
    /**
     * Close acknowledge popup confirm dialog
     */
    ackClose() {
      this.ackDialog = false;
      this.ackLoading = false;
      this.confirmAlertId = null;
    },
  },
  /**
   * Section require to show and hide the dialog window
   */
  watch: {
    dialog(val) {
      val || this.close();
    },
    ackDialog(val) {
      val || this.ackClose();
    },
  },
  mounted() {
    // Check if current user is part of @moonshothealth.com
    // Event bus events for the trigger operation
    // bus.$on(DEVICE_TRIGGER_RECORDING_ERROR, (error) => {
    //   this.recording.alertType = "error";
    //   this.recording.result = error;
    // });
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapState({
      building: (state) => state.building.currentBuilding,
      deviceCount: (state) => state.building.deviceCount,
      alerts: (state) => state.building.alerts,
    }),
    ...mapGetters(["isLoading"]),
    //
    // Calculate the tenant absence based on stats
    //
    totalAbsent() {
      if (this.building && this.building.getStats()) {
        return (
          this.building.getStats().getTotalStructures() -
          this.building.getStats().getTotalPresents()
        );
      }
      return 0;
    },
    offlineDevice() {
      if (this.deviceCount) {
        return this.deviceCount.totalDevices - this.deviceCount.onlineDevices;
      }
      return 0;
    },
    alertClass() {
      if (this.building && this.building.getStats()) {
        if (this.building.getStats().getTotalAlerts() > 0) {
          return "error";
        }
      }
      return "success";
    },
  },
  async beforeRouteEnter(to, from, next) {
    //const buildingid = store.getters.getCurrentBuilding.getBuildingId();
    store.dispatch(BUILDING_COUNT_DEVICE_REQUEST, to.params.buildingId);
    store.dispatch(BUILDING_FETCH_ALERTS_REQUEST, to.params.buildingId);
    next();
  },
};
</script>
